export default {
    header: {
        title: "Certifications and Accreditations"
    },
    hero: {
        image: "certifications and accreditations_testeira.jpg",
        title: "About Us",
        headline: "We are Flash Moves",
        breadcrumbs: [
            {
                label: "Home",
                url: "/"
            },
            {
                label: "About Us / Certifications and Accreditations",
                url: null
            }
        ]
    },
    mainContent: {
        title: "Certifications and Accreditations",
        segments: [
            {
                paragraphs: [
                    "Flash Moves works to exceed stringent industry standards by implementing a “measure to manage” philosophy across everything we do, from customer service through to operations and management.",
                    "Policies, procedures and instructions govern all aspects of our business:"
                ],
                bulletPoints: [
                    "To define objectives, structure, responsibilities and authorities",
                    "Document activities for confirmation and record-keeping purposes",
                    "Ensure that employees and subcontractors know how to comply always",
                    "Deliver an offering that exceeds best practice and customer requirements"
                ]
            },
            {
                paragraphs: [
                    "Our absolute commitment to quality and customer satisfaction in everything we do also includes:"
                ],
                bulletPoints: [
                    "Aligning our values with those of our customers",
                    "Provision of attentive, caring, friendly and reliable employees"
                ]
            }
        ]
    },
    seeMore: {
        title: "More About Us",
        subtitle: "For more information about us explore the links bellow, or contact us to get to know us personally",
        links: [
            {
                label: "History",
                url: "/about-us/history"
            },
            {
                label: "Vision and Principles",
                url: "/about-us/vision-and-principles"
            },
            {
                label: "Corporate Responsibility",
                url: "/about-us/corporate-responsibility"
            },
        ]
    }

};
