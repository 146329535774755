export default {
    header: {
        title: "Courier"
    },
    hero: {
        image: "courier_testeira.jpg",
        title: "Services",
        headline: "Courier",
        breadcrumbs: [
            {
                label: "Home",
                url: "/"
            },
            {
                label: "Services / Courier",
                url: null
            }
        ]
    },
    mainContent: {
        title: "Services",
        paragraphs: [
            "We are a one-stop solution to all your moving and delivery problems. From packing to relocating, we offer all that you have in mind when you think of moving. We make your requirement our wish making your moving experience as quick and easy as possible and the best part is that we are affordable.",
            "Our comprehensive courier services include:"
        ],
        segments: [
            {
                title: "Local & Point to Point",
                paragraphs: [
                    "Do you have goods which need to be sent across town? Our point to point services are the perfect solution for fast or off-peak deliveries."
                ]
            },
            {
                title: "Same Day",
                paragraphs: [
                    "When you have a time-critical delivery, we will find the solution, delivering your goods to the door as soon as possible.",
                ]
            },
            {
                title: "Priority Services",
                paragraphs: [
                    "We offer a range of priority and overnight delivery options when your goods need to be delivered the next business day.",
                ]
            }
        ]
    }
};
