import React from "react"
import { I18nextProvider, useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet'
import i18n from "../utils/i18n";
import { main } from './Layout.module.css';
import Header from './Header';
import Footer from './Footer';
import Scroller from './Scroller';

export default ({ children }) => {
    const { t } = useTranslation();

    const copy  = t('common.header', { returnObjects: true });

    return (
        <I18nextProvider i18n={i18n}>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"/>
                <meta name="Description" content={copy.meta.description} />
            </Helmet>
            <Header />
            <main>
                {children}
            </main>
            <Footer />
            <Scroller />
        </I18nextProvider>
    );
};
