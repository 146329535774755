import React from 'react';
import { Link } from "gatsby"
import { FaHeart, FaChevronRight, FaFacebookF, FaInstagram } from 'react-icons/fa';
import { main, logo, container, info, social, meta, merch } from './Footer.module.css';
import { useTranslation } from "react-i18next";
import Logo from '../components/Logo';
import useMediaQuery from '../hooks/useMediaQuery';

export default ({ children }) => {
    const { t } = useTranslation();

    const isDesktop = useMediaQuery('(min-width: 769px)');

    const sections = t('home.footer.sections', { returnObjects: true });

    return (
        <footer className={main}>
            <div className={container}>
                <div className={logo}><Logo orientation="vertical" fill="white" /></div>
                <div className={info}>
                    {sections.map((section, i) => i === sections.length  - 1 ? undefined : (
                        <div key={i}>
                            <h6>{section.title}</h6>
                            <ul>
                                {t(`home.footer.sections.${i}.items`, { returnObjects: true }).map((subsection, ii) => (
                                    <li key={ii}>
                                        <FaChevronRight size="0.6em"/>
                                        {subsection.title === "Provide a Review" ? (
                                            <a href={subsection.url} target="__blank" rel="noopener noreferrer">
                                                <span>{subsection.title}</span>
                                            </a>
                                        ) : (
                                            <Link
                                                to={subsection.url}
                                            >
                                                <span>{subsection.title}</span>
                                            </Link>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                    <div>
                        <h6>{t(`home.footer.sections.${sections.length - 1}.title`)}</h6>
                        <div className={social}>
                            <a
                                href="https://www.facebook.com/FlashMovesAustralia/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaFacebookF size={isDesktop ? '1em' : '2em'} style={{ cursor: 'pointer' }}/>
                            </a>
                            <a
                                href="https://www.instagram.com/flashmoves/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaInstagram size={isDesktop ? '1em' : '2em'} style={{ cursor: 'pointer' }}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={meta}>
                <small>{t('home.footer.copyright', { year: (new Date()).getFullYear() })}</small>
                {/* <small>{t('home.footer.privacyPolicy')}</small>
                <small>{t('home.footer.termsOfUse')}</small>
                <small>{t('home.footer.legal')}</small>
                <small>{t('home.footer.siteMap')}</small> */}
                <span className={merch}>
                    <small>
                        <span>Built with </span>
                        <FaHeart size={'0.8em'} style={{ verticalAlign: 'text-bottom', margin: '0 0.1em' }}/>
                        <span> by </span>
                        <a
                            href="https://tevincocin.studio/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Tevincocin
                        </a>
                    </small>
                </span>
            </div>
        </footer>
    );
};
