import React, { useState } from 'react';
import { navigate } from 'gatsby';
import isEmail from 'validator/es/lib/isEmail';
import isEmpty from 'validator/es/lib/isEmpty';
import { main, transparent, solid } from './GetQuoteForm.module.css';
import Button from './Button';
import { useTranslation } from "react-i18next";
import useGlobal from '../hooks/useGlobal';

export default ({ flavor = 'transparent', horizontal = null }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        firstName: {
            error: null
        },
        emailAddress: {
            error: null
        }
    })

    // eslint-disable-next-line no-unused-vars
    const [clientDetails, setClientDetails] = useGlobal('clientDetails', {
        firstName: '',
        emailAddress: ''
    });

    const goToQuotationForm = (event) => {
        event.preventDefault();

        const newState = {};

        const firstName = event.target.form.elements['first-name'].value;
        const emailAddress = event.target.form.elements['email-address'].value;

        let hasErrors = false;

        if (isEmpty(firstName, { ignore_whitespace: true })) {
            hasErrors = true;
            newState.firstName = {
                error: t('home.getQuoteForm.fields.firstName.errors.empty')
            };
        } else {
            newState.firstName = {
                error: null
            };
        }

        if (isEmpty(emailAddress, { ignore_whitespace: true })) {
            hasErrors = true;
            newState.emailAddress = {
                error: t('home.getQuoteForm.fields.emailAddress.errors.empty')
            };
        } else if (!isEmail(emailAddress)) {
            hasErrors = true;
            newState.emailAddress = {
                error: t('home.getQuoteForm.fields.emailAddress.errors.invalidFormat')
            };
        } else {
            newState.emailAddress = {
                error: null
            };
        }

        setState(newState);

        if (!hasErrors) {
            setClientDetails({ firstName, emailAddress });
            navigate('/get-a-quote');
        }
    };

    return (
        <form className={`${main} ${flavor === 'transparent' ? transparent : solid}`} data-horizontal={horizontal}>
            <div>
                <label htmlFor="first-name" hidden>{t('home.getQuoteForm.fields.firstName.label')}:</label>
                <input aria-label={t('home.getQuoteForm.fields.firstName.label')} type="text" placeholder={t('home.getQuoteForm.fields.firstName.placeholder')} id="first-name" />
                {state.firstName.error && <small>{state.firstName.error}</small>}
            </div>
            <div>
                <label htmlFor="email-address" hidden>{t('home.getQuoteForm.fields.emailAddress.label')}:</label>
                <input aria-label={t('home.getQuoteForm.fields.emailAddress.label')} type="email" placeholder={t('home.getQuoteForm.fields.emailAddress.placeholder')} id="email-address" />
                {state.emailAddress.error && <small>{state.emailAddress.error}</small>}
            </div>
            <Button
                onClick={goToQuotationForm}
                onKeyPress={goToQuotationForm}
            >
                {t('home.getQuoteForm.fields.submitButton.label')}
            </Button>
        </form>
    );
}
