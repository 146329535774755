import home from "./home";
import aboutUs from "./about-us";
import businessSolutions from "./business-solutions";
import services from "./services";
import contactUs from "./contact-us";
import getAQuote from "./get-a-quote";
import planMoving from "./plan-moving";

export default {
    translation: {
        home,
        aboutUs,
        businessSolutions,
        services,
        contactUs,
        getAQuote,
        planMoving,
        common: {
            header: {
                meta: {
                    description:
                        "Highly Rated Local & Interstate Moving Services. Call Us For A Free Quote Now! Let our specialists show you how easy moving is when you work with us.",
                },
            },
            internalBottom: {
                customerReviews: {
                    title: "Hear from our customers",
                },
                quotation: {
                    title: "Request a Free Quote",
                },
                faq: {
                    title: "A list of frequently asked questions to help you understand how it works.",
                    subtitle: "Find answers and general information",
                },
            },
            form: {
                sections: {
                    location: {
                        fields: {
                            buildingAccess: {
                                label: "Building Access",

                                options: [
                                    {
                                        label: "Other"
                                    },
                                    {
                                        label: "Stairs",
                                    },
                                    {
                                        label: "Ground floor",
                                    },
                                    {
                                        label: "Lift",
                                    },
                                ],
                            },
                        },
                    },
                    movingItems: {
                        title: "Moving Items",
                        categories: [
                            {
                                name: "Bedroom",
                                items: [
                                    { label: "Bed" },
                                    { label: "Wardrobe" },
                                    { label: "Bedside Table" },
                                    { label: "Cabinet" },
                                    { label: "Bookshelf" },
                                    { label: "Lamp" },
                                    { label: "Desk" },
                                    { label: "Chair" },
                                    { label: "Mattress" },
                                    { label: "Rug" },
                                    { label: "Bed head" },
                                    { label: "Chest of Drawers" },
                                    { label: "Tall Boy" },
                                    { label: "Mirror" },
                                    { label: "Dresser" },
                                    { label: "Stool" },
                                    { label: "Boxes" },
                                ],
                            },
                            {
                                name: "Kitchen",
                                items: [
                                    { label: "Single door Fridge" },
                                    { label: "Oven" },
                                    { label: "Table" },
                                    { label: "Chair" },
                                    { label: "Dishwasher" },
                                    { label: "Microwave" },
                                    { label: "Double door fridge" },
                                    { label: "Kitchen Tidy" },
                                    { label: "Boxes" },
                                ],
                            },
                            {
                                name: "Living Room",
                                items: [
                                    { label: "2 seater couch" },
                                    { label: "TV" },
                                    { label: "Coffee Table" },
                                    { label: "Rug" },
                                    { label: "Side Table" },
                                    { label: "Cabinet" },
                                    { label: "3 seater couch" },
                                    { label: "L shaped couch" },
                                    { label: "Sofa bed" },
                                    { label: "Buffet" },
                                    { label: "Mirror" },
                                    { label: "Plant" },
                                    { label: "TV Unit" },
                                    { label: "Artwork" },
                                    { label: "Lamp" },
                                    { label: "Table" },
                                    { label: "Chair" },
                                    { label: "Pictures" },
                                    { label: "Boxes" },
                                ],
                            },
                            {
                                name: "Laundry",
                                items: [
                                    { label: "Washing Machine" },
                                    { label: "Dryer" },
                                    { label: "Baskets" },
                                    { label: "Ironing Board" },
                                    { label: "Boxes" },
                                ],
                            },
                            {
                                name: "Study",
                                items: [
                                    { label: "Desk" },
                                    { label: "Chair/Stool" },
                                    { label: "Bookcase" },
                                    { label: "Filing Cabinet" },
                                    { label: "Computer" },
                                    { label: "Pictures " },
                                    { label: "Boxes" },
                                ],
                            },
                            {
                                name: "Outdoor",
                                items: [
                                    { label: "Pot Plants" },
                                    { label: "Lounge" },
                                    { label: "BBQ" },
                                    { label: "Table" },
                                    { label: "Chairs" },
                                    { label: "Bench" },
                                    { label: "Boxes" },
                                ],
                            },
                            {
                                name: "Garage",
                                items: [
                                    { label: "Mower" },
                                    { label: "Wheelbarrow" },
                                    { label: "Garden Tools" },
                                    { label: "Ladder" },
                                    { label: "Workmate" },
                                    { label: "Tool Box" },
                                    { label: "Bicycles" },
                                    { label: "Golf Clubs" },
                                    { label: "Trunks" },
                                    { label: "Suitcases" },
                                    { label: "Boxes" },
                                ],
                            },
                        ],
                    },
                },
            },
        },
    },
};
