import history from './history';
import visionAndPrinciples from './vision-and-principles';
import corporateResponsibility from './corporate-responsibility';
import certificationsAndAccreditations from './certifications-and-accreditations';

export default {
    history,
    visionAndPrinciples,
    corporateResponsibility,
    certificationsAndAccreditations,
};
