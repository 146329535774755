export default {
    header: {
        title: "Commercial Relocations"
    },
    hero: {
        image: "commercial relocation_testeira.jpg",
        title: "Services",
        headline: "Commercial Relocations",
        breadcrumbs: [
            {
                label: "Home",
                url: "/"
            },
            {
                label: "Services / Commercial Relocations",
                url: null
            }
        ]
    },
    mainContent: {
        title: "Services",
        paragraphs: [
            "We are a one-stop solution to all your moving and delivery problems. From packing to relocating, we offer all that you have in mind when you think of moving. We make your requirement our wish making your moving experience as quick and easy as possible and the best part is that we are affordable.",
            "With us, you will never experience a drop in dedication or speed. We will manage everything efficiently starting from your first point of contact to the deliverance of the last piece of your item.",
            "Save yourself the headache and hassle, and call Flash Moves today to speak to one of our knowledgeable specialists and let us take care of all the heavy lifting for you."
        ]
    }
};
