export default {
    header: {
        title: "Corporate Responsibility"
    },
    hero: {
        image: "corporate responsibility_testeira.jpg",
        title: "About Us",
        headline: "We are Flash Moves",
        breadcrumbs: [
            {
                label: "Home",
                url: "/"
            },
            {
                label: "About Us / Corporate Responsibility",
                url: null
            }
        ]
    },
    mainContent: {
        title: "Corporate Responsibility",
        segments: [
            {
                paragraphs: [
                    "Flash Moves aims to continually innovate its business in ways that benefit and support the community, including:"
                ],
                bulletPoints: [
                    "Acting socially responsibly and ethically in dealings with customers and suppliers",
                    "Better managing energy and water consumption for the life of our services",
                    "Applying sustainable practices to paper and card use, waste and emissions",
                    "Minimizing landfill by repairing, reusing or extending service warranties."
                ]
            },
            {
                paragraphs: [
                    "We also engage in partnerships with community organizations that deliver social and environmental initiatives."
                ],
                bulletPoints: [

                ]
            }
        ]
    },
    seeMore: {
        title: "More About Us",
        subtitle: "For more information about us explore the links bellow, or contact us to get to know us personally",
        links: [
            {
                label: "History",
                url: "/about-us/history"
            },
            {
                label: "Vision and Principles",
                url: "/about-us/vision-and-principles"
            },
            {
                label: "Certifications and Accreditations",
                url: "/about-us/certifications-and-accreditations"
            },
        ]
    }

};
