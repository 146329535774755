export default {
    header: {
        title: "Contact Us"
    },
    hero: {
        image: "contact us_testeira.jpg",
        title: "Contact Us",
        headline: "Get In Touch",
        breadcrumbs: [
            {
                label: "Home",
                url: "/"
            },
            {
                label: "Contact Us",
                url: null
            }
        ]
    },
    mainContent: {
        title: "Contact Us",
        paragraphs: [
            "Contact us to book your removalist now",
            "Fill out the form bellow"
        ]
    },
    form: {
        sections: {
            name: {
                title: "Name",
                fields: {
                    firstName: {
                        label: "First Name",
                        errors: {
                            empty: "This field can't be blank"
                        }
                    },
                    lastName: {
                        label: "Last Name",
                    }
                }
            },
            email: {
                title: "Email",
                fields: {
                    emailAddress: {
                        label: "Email address",
                        errors: {
                            invalidFormat: "Please use a valid email address"
                        }
                    }
                }
            },
            phoneNumber: {
                title: "Phone Number",
                fields: {
                    areaCode: {
                        label: "Area Code",
                        errors: {
                            empty: "This field can't be blank"
                        }
                    },
                    phoneNumber: {
                        label: "Phone number",
                        errors: {
                            empty: "This field can't be blank"
                        }
                    }
                }
            },
            messageUs: {
                title: "Message Us",
                fields: {
                    messageUs: {
                        label: "Message Us",
                        errors: {
                            empty: "This field can't be blank"
                        }
                    }
                }
            }
        },
        submitButton: {
            label: "Send"
        },
        result: {
            success: "Thank you! We'll get back shortly",
            error: "Oops! There was an error. Please, try again."
        }
    }
};
