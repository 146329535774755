export default {
    header: {
        title: "Get a Quote",
    },
    hero: {
        image: "quotation_testeira.jpg",
        title: "Quotation",
        headline: "Hobart and Sydney’s\n Best Removalists",
        breadcrumbs: [
            {
                label: "Home",
                url: "/",
            },
            {
                label: "Quotation",
                url: null,
            },
        ],
    },
    mainContent: {
        title: "Book And Quote",
        paragraphs: [
            "We are a one-stop solution to all your moving and delivery problems. From packing to relocating, we offer all that you have in mind when you think of moving. We make your requirement our wish making your moving experience as quick and easy as possible and the best part is that we are affordable. Contact our moving consultants to get the best quote for your needs today.",
        ],
    },
    form: {
        sections: {
            name: {
                title: "Name",
                fields: {
                    firstName: {
                        label: "First Name",
                        errors: {
                            empty: "This field can't be blank",
                        },
                    },
                    lastName: {
                        label: "Last Name",
                    },
                },
            },
            email: {
                title: "Email",
                fields: {
                    emailAddress: {
                        label: "Email Address",
                        errors: {
                            invalidFormat: "Please use a valid email address",
                        },
                    },
                },
            },
            phoneNumber: {
                title: "Phone Number",
                fields: {
                    areaCode: {
                        label: "Area Code",
                        errors: {
                            empty: "This field can't be blank",
                        },
                    },
                    phoneNumber: {
                        label: "Phone Number",
                        errors: {
                            empty: "This field can't be blank",
                        },
                    },
                },
            },
            dateAndTime: {
                title: "Date and Time",
                fields: {
                    date: {
                        label: "Date",
                    },
                    time: {
                        label: "Time",
                    },
                },
            },
            pickUpLocation: {
                title: "Pick up Location",
                fields: {
                    address: {
                        label: "Address",
                    },
                    importantObservations: {
                        label: "Important Observations",
                        placeholder: "E.g. Steep three floor stair climb followed by a deep and narrow corridor."
                    }
                },
            },
            dropOffLocation: {
                title: "Drop-off Location",
                fields: {
                    address: {
                        label: "Address",
                    },
                    importantObservations: {
                        label: "Important Observations",
                        placeholder: "E.g. Easy ground floor access."
                    }
                },
            },
        },
        submitButton: {
            label: "Get a quote",
        },
        result: {
            success: "Thank you! We'll get back shortly",
            error: "Oops! There was an error. Please, try again.",
        },
    },
};
