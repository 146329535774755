import { useEffect, useState } from 'react';

export default () => {
    const [state, setState] = useState({
        reviews: []
    });

    const getReviews = async () => {
        const url = `${process.env.GATSBY_API_URL}/v1/reviews`;
        const response = await fetch(url);
        const data = await response.json();
        return data.reviews;
    }

    useEffect(() => {
        (async ()  => {
            const reviews = await getReviews();
            setState(prevState => ({ ...prevState, reviews }));
        })();
    }, []);

    return state.reviews;
}
