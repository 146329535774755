export default {
    header: {
        title: "Delivery Solutions"
    },
    hero: {
        image: "delivery solutions_testeira.jpg",
        title: "Business Solutions",
        headline: "Delivery Solutions",
        breadcrumbs: [
            {
                label: "Home",
                url: "/"
            },
            {
                label: "Business Solutions / Delivery Solutions",
                url: null
            }
        ]
    },
    mainContent: {
        title: "Business Solutions",
        paragraphs: [
            "Flash Moves offer a wide range of services for businesses. From furniture deliveries to event logistics we can help your business with any part of the process working with your team to efficiently get the job done.",
            "We are able to work in long term basis as well as in short term basis, offering a great communication with our customers as well as an efficient approach. Your will always be one step ahead of what is happening."
        ]
    },
    internalBottom: {
        howDoesItWork: {
            title: 'How does it work?',
            paragraphs: [
                "You have a furniture shop or you are a property stylist we can deliver your furniture to your clients. You can choose if the bill is sent to your company or directly to your customer. Using a management system called Trello we update you of all jobs in real time so you know exactly what is going on."
            ]
        }
    }
};
