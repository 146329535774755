export default {
    header: {
        title: "Plan Moving"
    },
    hero: {
        image: "plan moving_testeira.jpg",
        title: "Plan Moving",
        headline: "Moving Tips & Tools",
        breadcrumbs: [
            {
                label: "Home",
                url: "/"
            },
            {
                label: "Plan Moving",
                url: null
            }
        ]
    },
    sections: [
        {
            title: "Plan Moving",
            list: [
                {
                    title: "First",
                    paragraphs: [
                        "Created a moving caddy – to have everything on hand so I wasn’t continually searching for it.  If the kids used it they knew where to return it back to."
                    ]
                },
                {
                    title: "Second",
                    paragraphs: [
                        "Book your removalist – try to book your removalist as soon as you know when you want to move. Booking in advance is crucial as many companies book up quite quickly and you may miss out on the date you want. This is particularly important if you’re looking to move interstate, or if you want to move during the removalist busy season (September to December).",
                        "Local moves: if you’re moving locally (i.e. moving less than 100km), your move will be priced based on the number of hours it takes to load and unload the truck.",
                        "Interstate moves: when moving interstate, the cost of your move will depend on how much space your belongings take up in the truck or container, and where you are moving to/from."
                    ]
                },
                {
                    title: "Third",
                    paragraphs: [
                        "Purge as you pack – take the opportunity while packing to purge items you no longer use. Sell, donate or throw these items away, don’t take items you no longer use to clutter up the new house. If you don’t have the opportunity to part with them before the move label the box to sell or donate so you don’t unpack it."
                    ]
                },
                {
                    title: "Fourth",
                    paragraphs: [
                        "Colour code the boxes – colour coding boxes so it’s easy to identify which room they belong in."
                    ]
                },
                {
                    title: "Fifth",
                    paragraphs: [
                        "Label each box – when labelling boxes write which room it belongs to along with the contents on the top and side of the box. By labelling the sides you can easily identify what it in it when the boxes are stacked."
                    ]
                },
                {
                    title: "Sixth",
                    paragraphs: [
                        "Rate each box – give each box a star or number based on the priority it needs to be unpacked.  eg 3 stars for high, 1 star for low (can take a few weeks if it needs to)."
                    ]
                },
                {
                    title: "Seventh",
                    paragraphs: [
                        "Keep it light – don’t make boxes too heavy, it’s easy to fill them up but consider how much they will weigh, you don’t want the bottom to break away."
                    ]
                },
                {
                    title: "Eighth",
                    paragraphs: [
                        "Where to start? – start packing in the kitchen, put heavy items at the bottom of a few boxes, then load those boxes with lighter items."
                    ]
                },
                {
                    title: "Ninth",
                    paragraphs: [
                        "Wrap breakables – use clothes, tablecloths, tea towel and/or towels to wrap around breakables."
                    ]
                },
                {
                    title: "Tenth",
                    paragraphs: [
                        "Wrap glasses – use socks to wrap around glasses."
                    ]
                },
                {
                    title: "Eleventh",
                    paragraphs: [
                        "Wrap artwork and/or large items – in doonas, quilts blankets etc."
                    ]
                },
                {
                    title: "Twelfth",
                    paragraphs: [
                        "Use pillows etc – use pillows, cushions and stuffed animals to pad things and keep them safe."
                    ]
                },
                {
                    title: "Thirteenth",
                    paragraphs: [
                        "Remember where cables go – take photos of how the electronics cables are connected to easily know how to reconnect them at the new house."
                    ]
                },
                {
                    title: "Fourteenth",
                    paragraphs: [
                        "Don’t lose screws – when removing screws from items place them in a zip lock bag and tape it to the piece of furniture it corresponds to so you don’t lose it."
                    ]
                },
                {
                    title: "Fifteenth",
                    paragraphs: [
                        "Tape cables to electronics – to prevent the confusion of what cable belongs to which electronic, tape the cables or cords to the base of it. I say base in case the tape leaves sticky marks it’s not so worrisome on the bottom of it."
                    ]
                },
                {
                    title: "Sixteenth",
                    paragraphs: [
                        "Eat from your fridge and pantry – don’t do big grocery shops before your move, plan dinners based on food you have in your fridge and pantry, try to use them up before you move."
                    ]
                },
                {
                    title: "Seventeenth",
                    paragraphs: [
                        "Think outside the box – don’t forget to pack items in baskets, hampers, suitcases."
                    ]
                },
                {
                    title: "Eighteenth",
                    paragraphs: [
                        "Pack an overnight bag – get each family member to pack an overnight bag, with their toiletries, toothbrush, PJ’s, a change of clothes to easily have on hand on the night of the move. Save searching through boxes and everybody can have a comfortable nights sleep."
                    ]
                },
                {
                    title: "Nineteenth",
                    paragraphs: [
                        "Sketch a plan – draw out an plan of where you want large furniture items placed in your new home.  ie where you would like your lounge to be positioned. This is handy if you aren’t around somebody else can look at the plan and place the items in the correct place for you, saves your back later."
                    ]
                },
                {
                    title: "Twentieth",
                    paragraphs: [
                        "Create an essentials box – an essentials box is a box of items you will need on the day of the move."
                    ]
                }
            ]
        }
    ],
    form: {
        sections: {
            name: {
                title: "Name",
                fields: {
                    firstName: {
                        label: "First Name",
                    },
                    lastName: {
                        label: "Last Name",
                    }
                }
            },
            email: {
                title: "Email",
                fields: {
                    emailAddress: {
                        label: "Email address",
                    }
                }
            },
            phoneNumber: {
                title: "Phone Number",
                fields: {
                    areaCode: {
                        label: "Area Code",
                    },
                    phoneNumber: {
                        label: "Phone number",
                    }
                }
            }
        },
        submitButton: {
            label: "Get my free uote"
        }
    }
};
