export default {
    header: {
        title: "Packing Services and Supplies"
    },
    hero: {
        image: "packing and unpacking_testeira.jpg",
        title: "Services",
        headline: "Packing Services and Supplies",
        breadcrumbs: [
            {
                label: "Home",
                url: "/"
            },
            {
                label: "Services / Packing Services and Supplies",
                url: null
            }
        ]
    },
    mainContent: {
        title: "Services",
        paragraphs: [
            "We are a one-stop solution to all your moving and delivery problems. From packing to relocating, we offer all that you have in mind when you think of moving. We make your requirement our wish making your moving experience as quick and easy as possible and the best part is that we are affordable.",
            "Using specifically designed and quality packing materials tested packing methods and fully trained moving professionals, Flash Moves ensures the safety of your belongings. Your team of removalists will wrap and package your items to prevent movement and risk of damage during transit.",
            "While we do have one of the lowest claim incident rates in the industry, we also offer transit insurance for that extra peace of mind."
        ]
    }
};
