import eventLogistics from './event-logistics';
import deliverySolutions from './delivery-solutions';

export default {
    eventLogistics,
    deliverySolutions,
    common: {
        whatDoWeDo: {
            title: 'What do we do?',
            paragraphs: [
                "We allocate one of our experienced logistics specialists to work with your team the most efficient ways to get the jobs done. You will always be one step ahead of what is happening making your customers happy and likely to come back.",
            ]
        }
    }
};
