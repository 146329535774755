import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locale/en';
import es from '../locale/es';
import pt from '../locale/pt';
import hi from '../locale/hi';
import zh from '../locale/zh';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en,
            es,
            pt,
            hi,
            zh,
        },
        lng: 'en',
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;
