import React, { /* useEffect,  */useState } from 'react';
import { Link } from "gatsby"
import { FaTimes } from 'react-icons/fa';
import { useTranslation } from "react-i18next";
import {
    main, desktop, mobile, open, closed, navigationItem, navigationDropdown

} from './MainNavigation.module.css';
import Button from '../components/Button';

export default ({ isDesktop, isDynamicHeaderVisible, isMobileNavigationOpen, toggleMobileNavigation }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        activeNavigationItemIndex: null,
    });

    const activateNavigationItem = (activeNavigationItemIndex) => {
        setState(prevState => ({
            ...prevState,
            activeNavigationItemIndex
        }));
    };

    const deactivateNavigationItem = () => {
        setState(prevState => ({
            ...prevState,
            activeNavigationItemIndex: null
        }));
    };

    const toggleNavigationItem = (activeNavigationItemIndex) => {
        setState(prevState => {
            if (activeNavigationItemIndex === prevState.activeNavigationItemIndex) {
                return {
                    ...prevState,
                    activeNavigationItemIndex: null
                };
            } else {
                return {
                    ...prevState,
                    activeNavigationItemIndex
                };
            }
        });
    };

    return isDesktop ? (
        <div className={`${main} ${desktop}`}>
            {t('home.header.sections', { returnObjects: true }).map((section, i) => (
                <div
                    key={i}
                    tabIndex={section.subsections.length > 0 ? '0' : undefined}
                    role={section.subsections.length > 0 ? 'button' : undefined}
                    onMouseEnter={section.subsections.length > 0 ? (() => activateNavigationItem(i)) : undefined}
                    onMouseLeave={section.subsections.length > 0 ? (() => deactivateNavigationItem()) : undefined}
                    className={navigationItem}
                >
                    {section.url ? (
                        <Link to={`${section.url}`}>
                            {section.title}
                        </Link>
                    ) : (
                        <span>{section.title}</span>
                    )}
                    {section.subsections.length > 0 && (
                        <div
                            className={navigationDropdown}
                            style={{
                                display: state.activeNavigationItemIndex === i ? '' : 'none',
                                background: isDynamicHeaderVisible === null ? 'none' : isDynamicHeaderVisible ? 'rgba(0,0,0,0.4)' : 'white'
                            }}
                        >
                            {section.subsections.map((subsection, ii) => (
                                <Link to={subsection.url} key={ii}>
                                    {subsection.title}
                                </Link>
                            ))}
                        </div>
                    )}
                </div>
            ))}
            <Link to={'/get-a-quote'}>
                <Button tabIndex="-1">{t('home.header.callToAction')}</Button>
            </Link>
        </div>
    ) : (
        <div className={`${main} ${mobile} ${isMobileNavigationOpen ? open : closed}`}>
            <div></div>
            <div>
                <div
                    onClick={toggleMobileNavigation}
                    onKeyPress={toggleMobileNavigation}
                    role="button"
                    tabIndex="0"
                >
                    <FaTimes color={'white'} size="2em"/>
                </div>
            </div>
            <div>
                {t('home.header.sections', { returnObjects: true }).map((section, i) => (
                    <div
                        key={i}
                        tabIndex={section.subsections.length > 0 ? '0' : undefined}
                        role={section.subsections.length > 0 ? 'button' : undefined}
                        onClick={section.subsections.length > 0 ? (() => toggleNavigationItem(i)) : undefined}
                        onKeyPress={section.subsections.length > 0 ? (() => toggleNavigationItem(i)) : undefined}
                        className={navigationItem}
                    >
                        {section.url ? (
                            <Link to={`${section.url}`}>
                                {section.title}
                            </Link>
                        ) : (
                            <span>{section.title}</span>
                        )}
                        {section.subsections.length > 0 && (
                            <div
                                className={navigationDropdown}
                                style={{
                                    display: state.activeNavigationItemIndex === i ? '' : 'none',
                                    background: isDynamicHeaderVisible === null ? 'none' : isDynamicHeaderVisible ? 'none' : 'white'
                                }}
                            >
                                {section.subsections.map((subsection, ii) => (
                                    <Link to={subsection.url} key={ii}>
                                        {subsection.title}
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div>
                <Link to={'/get-a-quote'}>
                    <Button tabIndex="-1">{t('home.header.callToAction')}</Button>
                </Link>
            </div>
        </div>
    );
}
