export default {
    header: {
        title: "Vision and Principles"
    },
    hero: {
        image: "vision and principles_testeira.jpg",
        title: "About Us",
        headline: "We are Flash Moves",
        breadcrumbs: [
            {
                label: "Home",
                url: "/"
            },
            {
                label: "About Us / Vision And Principles",
                url: null
            }
        ]
    },
    mainContent: {
        title: "Vision And Principles",
        segments: {
            vision: {
                title: "Our Vision",
                paragraphs: [
                    "To be the removals, storage and relocation service provider of choice through excellence in customer service and delivering value to our clients."
                ],
            },
            principles: {
                title: "Our Core Principles",
                paragraphs: [
                    "Our culture is represented by the way we act and work together. We operate according to three guiding principles:"
                ],
                items: [
                    {
                        title: "Excellence",
                        paragraphs: [
                            "Always strive to achieve the highest standards and ensure quality in everything we do."
                        ],
                        bulletPoints: [
                            "Exceed customer expectations",
                            "Deliver on our commitments (to customers and each other)",
                            "Work as one highly skilled and trained team",
                            "Develop flexible innovative solutions for customer challenges"
                        ]
                    },
                    {
                        title: "Integrity",
                        paragraphs: [
                            "Be open and honest in all our dealings. Do what we say we’ll do."
                        ],
                        bulletPoints: [
                            "Do the right thing by our customers, our business and each other",
                            "Demonstrate ethical behaviour (in relation to ourselves, others and our environment)",
                            "Respect and value views and opinions",
                            "Take responsibility and be accountable"
                        ]
                    },
                    {
                        title: "Care",
                        paragraphs: [
                            "Absolute commitment to our customers, people, business partners and the environment."
                        ],
                        bulletPoints: [
                            "Prioritize the health and safety of our people",
                            "Value diversity by adopting gender and cultural equality",
                            "Embody corporate responsibility in the way we conduct business, use resources and respect our environment",
                            "Take the utmost care with every move, from the big picture to the smallest detail, leaving customers to focus on enjoying their new home"
                        ]
                    }
                ]

            }
        },
    },
    seeMore: {
        title: "More About Us",
        subtitle: "For more information about us explore the links bellow, or contact us to get to know us personally",
        links: [
            {
                label: "History",
                url: "/about-us/history"
            },
            {
                label: "Certifications and Accreditations",
                url: "/about-us/certifications-and-accreditations"
            },
            {
                label: "Corporate Responsibility",
                url: "/about-us/corporate-responsibility"
            },
        ]
    }

};
