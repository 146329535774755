export default {
    header: {
        title: "Residential Moving"
    },
    hero: {
        image: "residential moving_testeira.jpg",
        title: "Services",
        headline: "Residential Moving",
        breadcrumbs: [
            {
                label: "Home",
                url: "/"
            },
            {
                label: "Services / Residential Moving",
                url: null
            }
        ]
    },
    mainContent: {
        title: "Services",
        paragraphs: [
            "We are a one-stop solution to all your moving and delivery problems. From packing to relocating, we offer all that you have in mind when you think of moving. We make your requirement our wish making your moving experience as quick and easy as possible and the best part is that we are affordable."
        ],
        segments: [
            {
                title: "Local",
                paragraphs: [
                    "Need to get moving? Flash Moves is the fastest and most convenient way to move your life around Hobart and Sydney.",
                    "Whether you need one man and a van or two(or three) men and a big truck, we've got you covered.",
                    "We’ll move just about anything - from a few boxes to your whole house. And our clear and simple pricing means you stay in control of the cost."
                ]
            },
            {
                title: "Interstate",
                paragraphs: [
                    "Whether you’re moving via car or you’re going by plane, you can trust Flash Moves to get your household goods to your new home when you arrive and you’re ready to move in.",
                    "It’s time to start moving, so get your obligation-free online quote today. Your dedicated moving consultant will work closely with you, helping you at every step of the process to provide a seamless interstate move."
                ]
            }
        ]
    }
};
