export default {
    header: {
        title: "History"
    },
    hero: {
        image: "history_testeira.jpg",
        title: "About Us",
        headline: "We are Flash Moves",
        breadcrumbs: [
            {
                label: "Home",
                url: "/"
            },
            {
                label: "About Us / History",
                url: null
            }
        ]
    },
    mainContent: {
        title: "History",
        paragraphs: [
            "Flash Moves has been moving people’s lives since 2017. Our values are professionalism, respect, teamwork, communication, safety, reliability and trust. Our team is our family and we only hire people who share our values and fit our culture. We like to think our family will be moving yours!",
            "We started out helping friends move a few boxes and furniture between houses and now we love helping hundreds of people transport their items successfully around Australia! Personalized service, comprehensive insurance, waste removal, prepack and unpack service, packing supplies, storage options, clean up - you'll be so impressed with how easy moving is when you work with us."
        ]
    },
    seeMore: {
        title: "More About Us",
        subtitle: "For more information about us explore the links bellow, or contact us to get to know us personally",
        links: [
            {
                label: "Vision and Principles",
                url: "/about-us/vision-and-principles"
            },
            {
                label: "Certifications and Accreditations",
                url: "/about-us/certifications-and-accreditations"
            },
            {
                label: "Corporate Responsibility",
                url: "/about-us/corporate-responsibility"
            },
        ]
    }

};
