import {
    FaCouch, FaStore, FaWarehouse, FaTruck, FaBoxes, FaDollyFlatbed, FaSuitcase,
    FaHandHoldingHeart, FaComments, FaClock, FaScrewdriver, FaCreditCard, FaRegCalendarAlt,
    FaPeopleCarry, FaDolly, FaClipboardCheck
} from 'react-icons/fa'

export default {
    header:  {
        sections: [
            {
                title: "About Us",
                url: null,
                subsections: [
                    {
                        title: "History",
                        url: "/about-us/history"
                    },
                    {
                        title: "Vision and Principles",
                        url: "/about-us/vision-and-principles"
                    },
                    {
                        title: "Certifications and Accreditations",
                        url: "/about-us/certifications-and-accreditations"
                    },
                    {
                        title: "Corporate Responsibility",
                        url: "/about-us/corporate-responsibility"
                    }
                ]
            },
            {
                title: "Services",
                url: null,
                subsections: [
                    {
                        title: "Residential moving",
                        url: "/services/residential-moving"
                    },
                    {
                        title: "Commercial relocations",
                        url: "/services/commercial-moving"
                    },
                    {
                        title: "Storage solutions",
                        url: "/services/storage-solutions"
                    },
                    {
                        title: "Courier",
                        url: "/services/local-removals"
                    },
                    {
                        title: "Packing Services and Supplies",
                        url: "/services/packing-supplies"
                    }
                ]
            },
            {
                title: "Quotation",
                url: "/get-a-quote",
                subsections: []
            },
            {
                title: "Business Solutions",
                url: null,
                subsections: [
                    {
                        title: "Event Logistics",
                        url: "/business-solutions/event-logistics"
                    },
                    {
                        title: "Delivery Solutions",
                        url: "/business-solutions/delivery-solutions"
                    }
                ]
            },
            {
                title: "Plan Moving",
                url: "/plan-moving",
                subsections: []
            },
            {
                title: "Contact Us",
                url: "/contact-us",
                subsections: []
            }
        ],
        callToAction: "Get a quote"
    },
    hero: {
        image: "IMG_2280.JPG",
        headline: "Who is Flash Moves?",
        paragraph: "Flash Moves is your friendly local transport company offering services in Hobart, Tasmania and Sydney, New South Wales. No job is too large or too small for our experienced team and you can always be assured of professional service when you hire us.",
        callToAction: {
            url: "/get-a-quote",
            label: "Get an estimate"
        }
    },
    getQuoteForm: {
        title: "Request a quote",
        fields: {
            firstName: {
                label: "First name",
                placeholder: "First name",
                errors: {
                    empty: "This field can't be blank"
                }
            },
            emailAddress: {
                label: "Email address",
                placeholder: "Email address",
                errors: {
                    empty: "This field can't be blank",
                    invalidFormat: "Please type in a valid email address"
                }
            },
            submitButton: {
                label: "Find availability"
            }
        }
    },
    whyUs: {
        headline: "History",
        paragraph: "We started out helping friends move a few boxes and furniture between houses and now we love helping hundreds of people transport their items successfully around Australia! Personalized service, comprehensive insurance, waste removal, prepack and unpack service, packing supplies, storage options, clean up - you'll be so impressed with how easy moving is when you work with us.",
        callToAction: {
            url: "/about-us/history",
            label: "Learn More"
        }
    },
    tabbedNavigation: [
        {
            title: "Residential\n moving",
            icon: FaCouch,
            image: "residential_homeservices.jpg",
            content: [
                "Flash Moves has been providing high-quality household moving services for families of all sizes in Hobart and Sydney. Our moving services have been highly rated by our customers. You can check our reviews on Google."
            ],
            bulletPoints: [
                "Energetic team",
                "Experienced  professionals",
                "Long distance moving"
            ],
            callToAction: {
                url: "/services/residential-moving",
                label: "Learn more"
            }

        },
        {
            title: "Commercial\n relocations",
            icon: FaStore,
            image: "Commercial Services.jpeg",
            content: [
                "Our qualified and efficient commercial movers are the ultimate choice for businesses of all types and sizes.",
                "This is because we keep ahead of all corporate moving services providers with a thorough understanding of the supplies and services required for a seamless and successful transition of your office and employee."
            ],
            bulletPoints: [],
            callToAction: {
                url: "/services/commercial-moving",
                label: "Learn more"
            }
        },
        {
            title: "Storage\n solutions",
            icon: FaWarehouse,
            image: "storage_homeservices.jpg",
            content: [
                "If you get to your new house or office and realise that some of your furniture and old files aren’t really needed at the new place we got you covered! Storage can be arranged either planned or at short notice."
            ],
            bulletPoints: [],
            callToAction: {
                url: "/services/storage-solutions",
                label: "Learn more"
            }
        },
        {
            title: "Courier",
            icon: FaTruck,
            image: "IMG_2231.JPG",
            content: [
                "Flash Moves takes a 'No Limits' approach to finding the best courier service with consideration for cost, time and the type of goods. Our flexible service allows customers to send both packed goods and fragile, oversized, highly valuable and unpacked items."
            ],
            bulletPoints: [],
            callToAction: {
                url: "/services/local-removals",
                label: "Learn more"
            }
        },
        {
            title: "Packing Services\n and Supplies",
            icon: FaBoxes,
            image: "packing unpacking_homeservices.jpg",
            content: [
                "We make packing and unpacking look easy. Our packing team goes above and beyond to get everything organized for your moving. They can also help you unpack everything and organize at your new home/office.",
                "You don’t need to move a finger to get your house completely relocated. We’ll provide as much or as little assistance as you need as part of our removalist service. We can provide onsite partial packing, partial unpacking as well as ‘packing only’ and ‘unpacking only’ services. Contact our moving consultants to get the best quote for your needs today."
            ],
            bulletPoints: [],
            callToAction: {
                url: "/services/packing-supplies",
                label: "Learn more"
            }
        },
        {
            title: "Event\n Logistics",
            icon: FaSuitcase,
            image: "event logistic_homeservices.jpg",
            content: [
                "Need removalists for events and expos? Want bump-ins and bump outs for a quick event activation? Need event planning with professionals?",
                "We are one of only those furniture removalists that have the necessary equipment, vehicles, premises and staff training needed to complete a professional move and covers transit insurance and public liability.",
                "Contact Flash Moves. We provide event removals, transport, storage, and logistics services.",
            ],
            bulletPoints: [],
            callToAction: {
                url: "/business-solutions/event-logistics",
                label: "Learn more"
            }
        },
        {
            title: "Delivery\n Solutions",
            icon: FaDollyFlatbed,
            image: "IMG_2199.JPG",
            content: [
                "We try to make the entire delivery process easy for both your company’s team and customers. Our delivery solutions brings so many advantages to our clients' businesses; the interface of our software, trained industry experts and the continual monitoring of performance focusing on business efficiency."
            ],
            bulletPoints: [],
            callToAction: {
                url: "/business-solutions/delivery-solutions",
                label: "Learn more"
            }
        }
    ],
    movingProcess: {
        title: "Our moving process",
        subTitle: "Moving is easy",
        steps: [
            {
                label: "Book our service",
                icon: FaRegCalendarAlt
            },
            {
                label: "We pack your goods",
                icon: FaPeopleCarry
            },
            {
                label: "We move your goods",
                icon: FaDolly
            },
            {
                label: "We unpack your goods",
                icon: FaClipboardCheck
            }
        ]
    },
    movingTips: {
        title: "Find out why we're",
        subtitle: "#1 Transport Company",
        list: [
            {
                title: "Care",
                icon: FaHandHoldingHeart,
                image: "care_home.jpg",
                content: "We go that extra mile to ensure your possessions are treated with the utmost respect and care. We want you to be satisfied with our removal services and to recommend us to others. The only way we can ensure your satisfaction is to provide the best service we possibly can. In addition to the protective blankets we also provide shrink wrap to be used during your move without any extra fee. We let you decide which items you want to be shrink wrapped."
            },
            {
                title: "Communication",
                icon: FaComments,
                image: "communication_home.jpg",
                content: "From the moment you contact us we offer you all the support from the booking to the payment. Before starting any relocation we ask you the right questions so we can optimise the time. Our team will be in touch to clarify any questions that may come up during the move process. With us you are taken care of."
            },
            {
                title: "On time",
                icon: FaClock,
                image: "ontime_home.jpg",
                content: "We understand that punctuality is one of the most important aspects of a move so we are always one step ahead when it comes to arrive on time. Our entire fleet associated with our operational team is ready to make sure we are o time and you are happy."
            },
            {
                title: "Assembly",
                icon: FaScrewdriver,
                image: "assembly_home.jpg",
                content: "We have the right tools and knowledge to disassemble and reassemble your furniture so you don’t need to worry about it. Just tell our moving consultants when you contact us for a quote as it might impact on the job time estimation or fixed quote."
            },
            {
                title: "No hidden costs",
                icon: FaCreditCard,
                image: "nohiddencosts_home .jpg",
                content: "We know many companies in the transport industry try to take advantage of the customer inputting hidden costs on the final price. We are the opposite, when you contact us for a quote we want to make sure you understand all the costs involved in your quote and nothing is a surprise by the end of the job."
            }
        ]
    },
    customerReviews: {
        image: "IMG_2256.JPG",
        headline: "Hear from our customers"
    },
    faq: {
        title: "Find answers and general information",
        subtitle: "A list of frequently asked questions to help you understand how it works.",
        items: [
            {
                question: "How do we charge?",
                answer: {
                    paragraphs: [
                        "Generally there are two main types of quotes, hourly rate and fixed price.",
                        "If you choose to pay hourly rate the time starts when we arrive at the pick up location and ends when we finish unloading the truck at the final destination. If call out fee applies it will be described on the quote. No hidden costs.",
                        "If you decide to get a fixed price we need a full list of items including boxes and descriptions of the accesses and parking at both properties. After receiving all those information our moving consultants will provide a fixed price for your move.",
                        "All prices are inclusive of GST."
                    ]
                }
            },
            {
                question: "How do we deal with heavy items?",
                answer: {
                    paragraphs: [
                        "Our experienced team goes above and beyond to get your job done. Even if there is a super heavy item such as piano we are ready to assist you. Just make sure to let our moving consultants know in advance so we can provide the right tools and vehicles to move your item."
                    ]
                }
            },
            {
                question: "What are the tools and protective materials we use?",
                answer: {
                    paragraphs: [
                        "Each truck is equipped with 1 trolley, 2 dollies, 30 blankets(40 blankets for 10 Tonne and above trucks), toolbox, drill set, 20 straps(30 for 10 tonne and above trucks) and 1 roll of glad wrap."
                    ]
                }
            },
            {
                question: "How do we take care of fragile items/antiques?",
                answer: {
                    paragraphs: [
                        "It’s our mission to move your belongings safely and securely. To ensure your items are moved unharmed, we carefully pad and shrink wrap them."
                    ]
                }
            },
            {
                question: "Can you pack my stuff or does it need to be packed?",
                answer: {
                    paragraphs: [
                        "We are happy to pack your stuff! Be sure to tell us prior to the move to ensure efficiency. To minimize costs, customers have the option to pack their belongings. Check out our Plan Moving section to make sure you do it the right way."
                    ]
                }
            }
        ]
    },
    footer: {
        sections: [
            {
                title: "Services",
                url: null,
                items: [
                    {
                        title: "Residential moving",
                        url: "/services/residential-moving"
                    },
                    {
                        title: "Commercial relocations",
                        url: "/services/commercial-moving"
                    },
                    {
                        title: "Storage solutions",
                        url: "/services/storage-solutions"
                    },
                    {
                        title: "Courier",
                        url: "/services/local-removals"
                    },
                    {
                        title: "Packing Services and Supplies",
                        url: "/services/packing-supplies"
                    },
                    {
                        title: "Event Logistics",
                        url: "/business-solutions/event-logistics"
                    },
                    {
                        title: "Delivery solutions",
                        url: "/business-solutions/delivery-solutions"
                    }
                ]
            },
            {
                title: "About Flash Moves",
                url: null,
                items: [
                    {
                        title: "History",
                        url: "/about-us/history"
                    },
                    {
                        title: "Vision and Principles",
                        url: "/about-us/vision-and-principles"
                    },
                    {
                        title: "Certifications and Accreditations",
                        url: "/about-us/certifications-and-accreditations"
                    },
                    {
                        title: "Corporate Responsibility",
                        url: "/about-us/corporate-responsibility"
                    }
                ]
            },
            {
                title: "Contact Us",
                url: null,
                items: [
                    {
                        title: "Contact Us",
                        url: "/contact-us"
                    },
                    {
                        title: "Online Quote",
                        url: "/get-a-quote"
                    },
                    {
                        title: "Provide a Review",
                        url: "https://g.page/flashmoves?share"
                    }
                ]
            },
            {
                title: "Social",
                url: null,
                items: []
            }
        ],
        copyright: "Copyright © {{year}} Flash Moves. All rights reserved",
        privacyPolicy: "Privacy Policy",
        termsOfUse: "Terms of Use",
        legal: "Legal",
        siteMap: "Site Map"
    }
};
