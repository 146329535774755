import React from "react"
import { FaChevronUp } from 'react-icons/fa';
import { main } from "./Scroller.module.css";


export default ({ children }) => {
    const scrollTop = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div
            className={main}
            onClick={scrollTop}
            onKeyPress={scrollTop}
            role="button"
            tabIndex="0"
        >
            <FaChevronUp size="20px"/>
        </div>
    );
}
