import React, { useEffect, useState } from 'react';
import { Link } from "gatsby"
import { IconContext } from 'react-icons';
import { FaFacebookF, FaInstagram, FaPhone, FaRegEnvelope, FaRegClock, FaBars } from 'react-icons/fa'
import {
    main, show, hide, dynamicHeader, staticHeader, socialInfoWrapper, socialInfo,
    contactInfoWrapper, contactInfo, logo, hamburger, whiteToTransparent, transparentToWhite
} from './Header.module.css';
import Logo from '../components/Logo';
import MainNavigation from '../components/MainNavigation';
import useMediaQuery from '../hooks/useMediaQuery';

export default ({ children }) => {
    const isDesktop = useMediaQuery('(min-width: 769px)');

    const [state, setState] = useState({
        hasBeenScrolled: false,
        isDynamicHeaderVisible: true,
        isBackgroundWhite: false,
        isMobileNavigationOpen: false
    });

    const toggleMobileNavigation = () => {
        setState(prevState => {
            return {
                ...prevState,
                isMobileNavigationOpen: !prevState.isMobileNavigationOpen
            }
        });
    } ;

    useEffect(() => {
        const scrollHandler = (e) => {
            setState(prevState => {
                const nextState = {}

                if (!prevState.hasBeenScrolled) {
                    nextState.hasBeenScrolled = true;
                }

                if (isDesktop) {
                    if (window.scrollY === 0) {
                        nextState.isDynamicHeaderVisible = true
                    } else {
                        nextState.isDynamicHeaderVisible = false;
                    }
                } else {
                    if (window.scrollY === 0) {
                        nextState.isBackgroundWhite = false
                    } else {
                        nextState.isBackgroundWhite = true;
                    }
                }

                return { ...prevState, ...nextState };
            });
        }

        window.addEventListener('scroll', scrollHandler);

        return () => window.removeEventListener('scroll', scrollHandler);
    }, [isDesktop]);

    return (
        <header
            className={`
                ${main}
                ${state.hasBeenScrolled ? state.isBackgroundWhite ? transparentToWhite : whiteToTransparent : ''}
                ${state.hasBeenScrolled ? state.isDynamicHeaderVisible ? show : hide : ''}
            `}
        >
            <IconContext.Provider value={{ size: '0.9em'}}>
                <div className={dynamicHeader}>
                    <div className={socialInfoWrapper}>
                        <a
                            className={socialInfo}
                            href="https://www.facebook.com/FlashMovesAustralia/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span><FaFacebookF size="1em" /></span>
                        </a>
                        <a
                            className={socialInfo}
                            href="https://www.instagram.com/flashmoves/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span><FaInstagram size="1em" /></span>
                        </a>
                    </div>
                    <div className={contactInfoWrapper}>
                        <a
                            href="tel:0421468086"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span className={contactInfo}>
                                <FaPhone style={{ transform: 'scaleX(-1)' }}/>
                                <small>(+61) 421 468 086</small>
                            </span>
                        </a>
                        <a
                            href="mailto:contact@flashmoves.com.au"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span className={contactInfo}>
                                <FaRegEnvelope />
                                <small>contact@flashmoves.com.au</small>
                            </span>
                        </a>
                        <div>
                            <span className={contactInfo}>
                                <FaRegClock />
                                <small>Mon - Sat:&nbsp;&nbsp;&nbsp;07:00 - 17:00</small>
                            </span>
                        </div>
                    </div>
                </div>
            </IconContext.Provider>
            <div className={staticHeader}>
                <div className={logo}>
                    <Link to={'/'}>
                        <Logo
                            fill={
                                state.hasBeenScrolled ?
                                    state.isDynamicHeaderVisible && !state.isBackgroundWhite ?
                                        'white'
                                        : 'colored'
                                    : 'white'
                            }
                        />
                    </Link>
                </div>
                <div
                    onClick={toggleMobileNavigation}
                    onKeyPress={toggleMobileNavigation}
                    role="button"
                    tabIndex="0"
                    className={hamburger}
                >
                    <FaBars color={state.isBackgroundWhite ? 'var(--red)' : 'white'} size="2em"/>
                </div>
                <MainNavigation
                    isDesktop={isDesktop}
                    toggleMobileNavigation={toggleMobileNavigation}
                    isMobileNavigationOpen={state.isMobileNavigationOpen}
                    isDynamicHeaderVisible={state.isDynamicHeaderVisible}
                />
            </div>
        </header>
    );
}
