import { useState } from 'react';

export default (key, value) => {
    const [globalValue, setGlobalValue] = useState(() => {
        if((global || window)['__GLOBAL_FLASHMOVES__'] === undefined) {
            (global || window)['__GLOBAL_FLASHMOVES__'] = {};
        }

        if ((global || window)['__GLOBAL_FLASHMOVES__'][key] === undefined)  {
            (global || window)['__GLOBAL_FLASHMOVES__'][key] = value;
        }

        return (global || window)['__GLOBAL_FLASHMOVES__'][key];
    });

    const setValue = value => {
        (global || window)['__GLOBAL_FLASHMOVES__'][key] = value;
        setGlobalValue((global || window)['__GLOBAL_FLASHMOVES__'][key]);
    };

    return [globalValue, setValue];
}
