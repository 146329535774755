export default {
    header: {
        title: "Storage Solutions"
    },
    hero: {
        image: "storage solutions_testeira.jpg",
        title: "Services",
        headline: "Storage Solutions",
        breadcrumbs: [
            {
                label: "Home",
                url: "/"
            },
            {
                label: "Services / Storage Solutions",
                url: null
            }
        ]
    },
    mainContent: {
        title: "Services",
        paragraphs: [
            "We are a one-stop solution to all your moving and delivery problems. From packing to relocating, we offer all that you have in mind when you think of moving. We make your requirement our wish making your moving experience as quick and easy as possible and the best part is that we are affordable.",
            "We can offer discounted storage at unbeatable prices. This is a secured storage, not a self-storage.",
            "The main difference between self-storage and secured storage is the storing method. With self-storage the customer is in charge of the storage lock-up. This means you have unrestricted access to your stored items and you control the lock to the unit.",
            "With our secured storage, we are the key holders. Our Staff can only have access to our facility and as such, you have a limited access.This may not suit the majority of people, however if you are travelling overseas for a while or have little need for your stored items, then this can be a better solution for you.",
            "Prices are low since you only pay for the space that you use and we are confident we can beat any storage quote you may have received.",
            "Also you need to use our removal services to store your items. This condition applies when bringing your items in and taking your items out of storage."
        ]
    },
    internalBottom: {
        howDoesItWork: {
            title: 'How does it work?',
            paragraphs: [
                "The cost is based on the amount of stuff you have and it is charged monthly. If you use less than a month you pay proportionally."
            ]
        },
        whatDoWeDo: {
            title: 'What do we do?',
            paragraphs: [
                "We make a full list of what you are putting into storage so it is easier for you to check if everything has been delivered back to your place. We have very competitive rates for all your storage needs."
            ]
        }
    }
};
