import { useEffect, useState } from 'react';

export default (query) => {
    const [value, setValue] = useState(null);

    useEffect(() => {
        const value = window.matchMedia(query);

        setValue(value);
    }, [query])

    return value ? value.matches : null;
}
