import commercialMoving from './commercial-moving';
import localRemovals from './local-removals';
import packingSupplies from './packing-supplies';
import residentialMoving from './residential-moving';
import storageSolutions from './storage-solutions';

export default {
    commercialMoving,
    localRemovals,
    packingSupplies,
    residentialMoving,
    storageSolutions,
    common: {
        howDoesItWork: {
            title: 'How does it work?',
            paragraphs: [
                'Generally there are two main types of quotes, hourly rate and fixed price.',
                "If you choose to pay hourly rate the time starts when we arrive at the pick up location and ends when we finish unloading the truck at the final destination. If call out fee applies it will be described on the quote. No hidden costs.",
                "If you decide to get a fixed price we need a full list of items including boxes and descriptions of the accesses and parking at both properties. After receiving all those informations our moving consultants will provide a fixed price for your move.",
                "All prices are inclusive of GST."
            ]
        },
        whatDoWeDo: {
            title: 'What do we do?',
            paragraphs: [
                "In addition to our expert removal services, we can also help with the jobs associated with moving such as the dismantling and assembling of furniture, packing services and expert help with fragile or antique items. When we say you can leave everything to us we really mean it. Moving to a new home or business premises doesn't have to be a stressful experience. When you book our services we give you complete peace of mind that your possessions are packaged safely and transported securely.",
            ]
        }
    }
};
