import { useStaticQuery, graphql } from "gatsby"

export default () => {
    const images = useStaticQuery(graphql`
        query GetMovingTipsImages {
            allImageSharp {
                nodes {
                    resize(width: 1200) {
                        src
                        tracedSVG
                        width
                        height
                        aspectRatio
                        originalName
                    }
                    fluid(jpegQuality: 100) {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                    }
                }
            }
        }
    `);

    return images.allImageSharp.nodes;
}
