export default {
    header: {
        title: "Event Logistics"
    },
    hero: {
        image: "event logistics_testeira.jpg",
        title: "Business Solutions",
        headline: "Event Logistics",
        breadcrumbs: [
            {
                label: "Home",
                url: "/"
            },
            {
                label: "Business Solutions / Event Logistics",
                url: null
            }
        ]
    },
    mainContent: {
        title: "Business Solutions",
        paragraphs: [
            "Flash Moves offer a wide range of services for businesses. From furniture deliveries to event logistics we can help your business with any part of the process working with your team to efficiently get the job done.",
            "We are able to work in long term basis as well as in short term basis, offering a great communication with our customers as well as an efficient approach. Your will always be one step ahead of what is happening."
        ]
    },
    internalBottom: {
        howDoesItWork: {
            title: 'How does it work?',
            paragraphs: [
                "The time starts when we arrive at the first pick up location and ends when we finish unloading the truck at the final destination. If call out fee applies it will be described on the quote. No hidden costs."
            ]
        }
    }
};
