
import React from 'react';
import Img from 'gatsby-image';
import { main, overlay } from './BackgroundImage.module.css';

export default ({ children, imageData, opacity}) => {
    return (
        <div className={main} >
            <div className={overlay} style={{ opacity }}></div>
            {imageData && <Img
                fluid={imageData.fluid}
                style={{
                    position: 'static',
                    minHeight: '100%'
                }}
                imgStyle={{
                    minHeight: '100%'
                }}
            />}
        </div>
    );
};
